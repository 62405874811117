<template>
  <Layout ref="main">
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card" id="card_section">
          <div class="card-title p-3 py-2 border-bottom">
            <div class="d-lg-flex align-items-center">
              <div class="me-2 d-inline-block"><i class="uil uil-file-alt display-6 text-primary"></i></div>
              <div class="mb-2 mb-lg-0 d-inline-block">
                <h4 class="fw-medium font-size-20 text-dark mb-0"> {{ title }}</h4>
                  <small class="d-block font-size-13 text-muted fw-normal">A document expressing support for a particular cause, project, or individual.</small>
              </div>

              <div class="ms-auto mb-lg-0 mb-2">
                <button class="btn btn-outline-info fw-medium me-2" v-if="create_modify_permission" @click="$refs.modalFunc2.showModal('letterSupport')">
                  <i class="uil uil-plus font-size-16 me-1"></i> New Letter Support
                </button>
              </div>
            </div>
          </div>
          
          <div class="card-body">
            <div class="row">
              <div class="col-12 col-md-3 mb-3">
                <label class="form-label mb-1">Type of Letter of Support</label>
                    <select class="form-select pe-5" v-model="typeOfLetter" @change="handlePageChange(1);" :disabled="disabled">
                      <option value="">All</option>
                      <option value="general">General</option>
                      <option value="quotation">Quotation</option>
                      <option value="ca40">CA40</option>
                    </select>
              </div>
              <div class="col-12 col-md-4 mb-3">
                <label class="form-label mb-1">Applicant Name</label>
                <input
                    type="text"
                    placeholder="Company Name, Contact Name"
                    class="form-control"
                    :disabled="disabled"
                    v-model.trim="searchKeyword"
                  />
              </div>
              <div class="col-12 col-md-auto mb-3">
                <label class="form-label mb-1">Sort By</label>
                    <select class="form-select pe-5" v-model="sortBy" @change="handlePageChange(1);" :disabled="disabled">
                      <option value="">Default</option>
                      <option value="name_a_z">Company Name (A-Z)</option>
                      <option value="name_z_a">Company Name (Z-A)</option>
                    </select>
              </div>
              
              <div class="col-12 m-0 p-0"></div>
              <div class="col-12 col-lg-4 col-xl-auto">
                <label class="form-label mb-1">Filter By</label>
                <div class="input-group mb-3">
                    <select class="form-select pe-5" v-model="filterType">
                      <option value="created_date">Created Date</option>
                      <option value="updated_date">Updated Date</option>
                    </select>
                  </div>
              </div>
              <div class="col-12 col-lg col-xl-4 mb-0">
                <label class="form-label mb-1">Date Range</label>
                <date-picker
                  v-model="date_range"
                  type="date"
                  range
                  value-type="format" 
                  format="YYYY-MM-DD"
                  placeholder="Select Date Range"
                  :disabled="disable_date"
                  :disabled-date="notBeforeToday"
                  :clearable="false"
                  :editable="false"
                  :shortcuts="shortcuts"
                  class="mb-3"
                ></date-picker>
                <!-- 
                  @change="DateChanged()" -->
              </div>
             
              <div class="col-12 col-md-auto col-xl-auto">
                <label class="form-label mb-1 d-md-block d-none">&nbsp;</label>
                <div class="row">
                  <div class="col-6 pe-1 col-auto d-grid">
                    <button
                      class="btn btn-light mb-3 text-nowrap"
                      type="button"
                      :disabled="disabled"
                      @click="clear()"
                    >
                      <i class="uil-redo me-1"></i> Clear
                    </button>
                  </div>
                  <div class="col-6 ps-1 col-auto d-grid">
                    <button
                      class="btn btn-info text-white mb-3 text-nowrap"
                      type="button"
                      :disabled="disabled"
                      @click="search()"
                    >
                      <i class="uil-search me-1"></i> Search
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-12 col-xl">
                <div class="float-end">
                  <div class="mb-3 ml-auto d-none ">
                    <b-button
                      type="submit"
                      variant="info"
                      @click="exportData()"
                      :disabled="disableExport"
                    
                    >
                      <i class="uil-file-export me-1"></i>
                      Export
                      <div
                        class="spinner-border spinner-border-sm text-white"
                        v-if="loadingButton"
                        role="status"
                      ></div>
                    </b-button>
                    
                  </div>
                </div>
              </div>
            </div>
            <div class="table-responsive text-nowrap font-size-14 position-relative">
              <!-- <div class="position-relative">
                <b-button id="tooltip-button-interactive">My tooltip is interactive</b-button>
                <b-tooltip target="tooltip-button-interactive">I will stay open when hovered</b-tooltip>
              </div> -->
              <table id="freeze" class="table mt-2 mb-0 align-middle min-width-760" :class="{'table-hover':returnData.length>0 && !loading}">
                <thead class="text-uppercase">
                  <tr>
                    <th>Id</th>
                    <th>Company</th>
                    <th>Type of Letter</th>
                    <th>Subject</th>
                    <th>Attachments</th>
                    <th>Created Date</th>
                    <th>Updated Date</th>
                    <th>Status</th>
                    <th>Notes</th>
                    <th v-if="create_modify_permission">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="!returnData.length && !loading">
                    <td :colspan="(create_modify_permission) ? 10 : 9" height="300" class="text-center text-muted">
                      <i class="uil uil-file-alt me-1"></i> No Data Available
                    </td>
                  </tr>
                  <tr v-if="loading">
                    <td :colspan="(create_modify_permission) ? 10 : 9" class="text-center text-muted" height="400">
                      <div
                        class="spinner-border text-secondary my-2"
                        role="status"
                      >
                        <span class="sr-only">Loading...</span>
                      </div>
                      <br />
                      <div>Loading...</div>
                    </td>
                  </tr>
             
                  <tr v-for="(value, index) in returnData" :key="index">
                    <td>
                      {{ value.id }}
                    </td>
                    <td> 
                      {{ value.applicant_info.company_name }}<br>
                      {{ value.applicant_info.business_registration_no }}
                    </td>
                    <td> 
                      <span class="badge bg-soft-info font-size-14 text-capitalize">{{ value.type_of_support}}</span> 
                    
                    </td>
                    <td> 
                      {{ value.subject }}
                    </td>
                    <td> 
                      <div v-if="value.files.find(e => e.files !== '')">
                        <div v-for="(v, i) in value.files" :key="i">
                          <a
                            v-if="v.files !== ''"
                            :href="v.files"
                            target="_blank"
                            :title="v.file_name"
                            ><i
                              class="uil uil-file-alt font-size-16 text-primary"
                            ></i>
                            {{v.file_name}} </a
                          >
                        </div>
                      </div>
                      <div v-else>-</div>
                    </td>

                    <td> 
                      {{ value.created_at }}
                    </td>   
                    <td>{{ value.date_updated || '-' }}</td>
                    <td>
                      <span class="badge bg-soft-success font-size-14" v-if="value.status =='approved'">Approved</span> 
                      <span class="badge bg-soft-purple font-size-14" v-if="value.status =='sent'">Sent</span> 
                      <span class="badge bg-soft-secondary font-size-14" v-if="value.status =='draft'">Draft</span> 
                      <span class="badge bg-soft-danger font-size-14" v-else-if="value.status =='rejected'"
                        v-b-tooltip.hover :title="value.reason">Rejected</span>   
                    </td>
                    <td class="text-muted">
                      <div v-if="value.notes.length">
                        {{  value.notes[0].notes }}
                      </div>
                      <div v-else>
                        N/A
                      </div>
                    </td>
                  
                    <td class="text-center" v-if="create_modify_permission">
                      <div>
                        <button class="btn btn-outline-info px-2 py-1 font-size-15 rounded" @click="edit(value)" :disabled="disabled">
                        <i class="uil uil-edit"></i></button>
                        <button class="btn btn-outline-danger px-2 py-1 font-size-15 rounded ms-2" @click="remove(value)" :disabled="disabled">
                        <i class="uil uil-trash-alt"></i></button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row" v-if="returnData.length">
              <div class="col mt-3">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      hide-ellipsis
                      @change="handlePageChange"
                      v-model="currentPage"
                      :per-page="perPage"
                      :total-rows="totalData"
                    >
                    </b-pagination>
                  </ul>
                </div>
              </div>
            </div>
           
          </div>
        </div>
      </div>
    </div>
    <Lottie :path="'/animate/loading_purple.json'" :title="'Loading'" :info="'Please wait...'" :show="showLottie" ref="lottieFunc" />
    <Common ref="commonFunc"/>
    <createAutoGenerate ref="modalFunc2" :data="{'accessUsername':accessUsername, 'assessToken':accessToken, 'updateData':updateData}" @callParentFunction="handlePageChange(currentPage)"/>
  </Layout>
</template>

<script>

import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Lottie from "@/components/lottieView";
import Common from "@/components/common";
import axios from "axios";
import Swal from "sweetalert2";
import DatePicker from "vue2-datepicker";
import createAutoGenerate from "@/views/pages/app/modal/createAutoGenerate";
/**
 * Page
 */
const PageTitle = "Letter of Support"

export default {
  components: { 
    Layout, 
    PageHeader, 
    Lottie,
    Common,
    DatePicker,
    createAutoGenerate
  },
  page() {
    return {
      title: PageTitle,
      meta: [
        {
          name: "description",
          content: appConfig.description,
        },
      ],
    }
  },
  data() {
    return {
      accessToken:'',
      accessEmail:'',
      accessPhone:'',
      accessUsername:'',
      showLottie:false,
      title: '',
      items: [
        {
          text: appConfig.pageTitle,
          to: "/",
        },
        {
          text: '',
          active: true,
        },
      ],
      formData:{
        apiID: "YOUR_API_ID",
        apiHash:"YOUR_API_HASH",
      },
      submitted:false,
      loading:false,
      buttonLoading:[false,false,false],
      currentPage: 1,
      seletedMethod: "",
      sortBy:"",
      typeOfLetter:"",
      searchKeyword: "",
      searchMerchantItemId:"",
      filterType:"created_date",
      searchAmount:"",
      perPage: 20,
      totalData: 0,
      returnData: [],
      returnPaidTotal: 0,
      returnPayoutProcessed:0,
      export_data: [],
      search_value: "",
      disableExport: false,
      loadingButton: false,
      date_range: null,
      disable_date: false,
      disabled:false,
      shortcuts:[],
      create_modify_permission:false,
      updateData:{}
    };
  },
  middleware: "authentication",
  async mounted(){
    this.reload();
    this.title = PageTitle
    this.items[1].text = PageTitle
    this.accessToken = this.$refs.commonFunc.getToken()
    this.accessUsername = this.$refs.commonFunc.getUsername()
    this.date_range = this.$refs.commonFunc.last30Days()
    this.shortcuts = this.$refs.commonFunc.datePickerShortcut()
    await this.handlePageChange(1);
    
  }, 
  created(){
    
  },
  methods:{
      convertCurrencyFormat(value,show00) {
        if (show00==true) {
          return this.$refs.commonFunc.convertCurrencyFormat(value)
        }else{
           // Assuming this.transactionLimit is defined in your component's data
           return this.$refs.commonFunc.convertCurrencyFormat(value).replaceAll(".00","");
        }
      },
      notBeforeToday(date) {
        return date > new Date(new Date().setHours(0, 0, 0, 0));
      },
      DateChanged() {
        console.log("datechange" + this.date_range);
        this.handlePageChange(1);
        this.currentPage = 1;
        this.disable_date = true;
      },
      handlePageChange(current_page) {
        this.pageNum = current_page;
        this.currentPage = current_page;
        this.getData(this.pageNum, this.searchKeyword);
        //this.exportData(1)
      },
      edit(value){
        this.updateData=value;
        setTimeout(() =>  this.$refs.modalFunc2.showEditModal("letterSupport"),100)
      },
      remove(value) {
        Swal.fire({
          icon: 'info',
          title: `Confirm Deletion?`,
          html: `<p> Are you sure you want to delete this ${value.subject} from ${value.applicant_info.company_name}?</p> <p class="mb-0"> This action is irreversible, so make sure you want to proceed.</p>`,
          confirmButtonColor: '#FA6541',
          iconColor: '#202360',
          showCancelButton: true,
          confirmButtonText: "Yes"
        }).then((result) => {
          if (result.isConfirmed) {
            this.$Progress.start();
            this.loading = true
            this.disabled = true
            var bodyFormData = new FormData();
            bodyFormData.append("accessToken", this.accessToken);
            bodyFormData.append("accessUsername", this.accessUsername);
            bodyFormData.append("letterDbCode", value.letter_db_code);
            axios({
              method: "post",
              url: appConfig.APIHost + "controller/letterOfSupport/deleteLetterOfSupport",
              data: bodyFormData,
              headers: {
                "Content-Type": "multipart/form-data"
              },
            }).then((response) => {
              var resData = response.data;
              if (resData.status == 200) {
                console.log(resData)
                this.returnData = []
                Swal.fire({
                  icon: 'success',
                  title: `Deleted Successfully`,
                  html: `The ${value.subject} from ${value.applicant_info.company_name} has been successfully deleted.`,
                  confirmButtonColor: '#FA6541',
                  confirmButtonText: this.$t('siteLang.Done')
                })
                this.handlePageChange(1)
              } else if (resData.status == 440) {
                Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                this.$refs.commonFunc.clearData()
                this.$router.push({
                  path: "/login",
                });
              } else {
                Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                this.loading = false
              }
              this.disabled = false
              this.$Progress.finish();
            }).catch((error) => {
              this.loading = false
              this.disabled = false;
              this.$Progress.finish();
              //Swal.fire("Error", error, "error");
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                html: `${error}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
            });
          }
        })
      },
      search() {
        this.handlePageChange(1);
        this.currentPage = 1;
      },
      clear() {
        this.sortBy= ""
        this.typeOfLetter=""
        this.searchKeyword= ""
        this.filterType="created_date"
        this.date_range = this.$refs.commonFunc.last30Days()
        this.handlePageChange(1);
        this.currentPage = 1;
      },

      exportToExcel(Data,name,type) {
        var title = name.replace(/ /g, "_");
        var ws = window.XLSX.utils.json_to_sheet(Data);
        let objectMaxLength = []; 
            for (let i = 0; i < Data.length; i++) {
            let value = Object.values(Data[i]);
            for (let j = 0; j < value.length; j++) {
                if (typeof value[j] == "number") {
                objectMaxLength[j] = 5;
                } else {
                objectMaxLength[j] =
                    objectMaxLength[j] >= value[j].length
                    ? parseInt(objectMaxLength[j])
                    : parseInt(value[j].length) + 5;
                }
            }
        }
        var wscols =[]
        objectMaxLength.forEach((value, index) => {
          console.log(value)
          wscols.push({width: objectMaxLength[index]})
        }),
        ws['!cols'] = wscols;
        
        var wb = window.XLSX.utils.book_new();
        window.XLSX.utils.book_append_sheet(wb, ws, name)
        window.XLSX.writeFile(wb, title +'.' + (type || 'xlsx'))
    },
    async getData(pages, keyword){
        this.$Progress.start();
        this.loading = true
        this.disabled = true
        this.disable_date = true;
        this.returnData = [];
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("accessUsername",  this.accessUsername);
        bodyFormData.append("page", pages);
        bodyFormData.append("type", this.typeOfLetter);
        bodyFormData.append("limit", this.perPage);
        bodyFormData.append("keyword", keyword);
        bodyFormData.append("sortBy", this.sortBy);
        bodyFormData.append("filterBy", this.filterType);
        bodyFormData.append("dateStart", this.date_range[0]);
        bodyFormData.append("dateEnd", this.date_range[1]);
        
        axios({
            method: "post",
            //url: `/api/letter_of_support.json`,
            url: appConfig.APIHost + 'controller/letterOfSupport/getAllLetterOfSupportList',
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            this.returnData = [];
            var resData = response.data;
            this.totalData = resData.total
            var responseData = resData.data
            
            if (resData.status == 200) {
              this.returnData = responseData
            } 
            else if (resData.status == 440){
              Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                localStorage.clear();
                this.$router.push({
                    path: "/login",
              });
            }
            else {
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
            }
            this.loading = false;
            this.disabled = false
            this.disable_date = false;
            this.$Progress.finish();
        })
        .catch((error)=> {
            this.loading = false;
            this.disabled = false
            this.disable_date = false;
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire(
              {
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
      });
    },
    
    async reload() {
      try {
        await this.$nextTick(); // Wait for the component to be fully mounted

        const commonFunc = this.$refs.commonFunc;
        if (commonFunc && commonFunc.getFullData) {
          const data = await commonFunc.getFullData();
          this.account_type = data.account_type//data.account_type;
          const permission = data.modules.filter(e => e.name==='letter_of_support')
          if (permission) {
            this.create_modify_permission = permission[0].create_modify_permission==0 ? false : true; 
          } else {
            console.log('Module with name "letter_of_support" not found.');
          }
        } else {
          // Handle the case when commonFunc or getFullData is undefined
          // Perform appropriate actions or show a fallback message to the user
        }
      } catch (error) {
        // Handle the error silently without propagating it further
      }
    },

  }
};
</script>